
const Privacy = () => {
  return (
    <div className="container mx-auto p-4 md:p-8">
      <h1 className="text-3xl font-bold mb-4">KİŞİSEL VERİLERİN KORUNMASINA VE İŞLENMESİNE DAİR ONAY FORMU</h1>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">GENEL BİLGİ</h2>
        <p className="mb-4">
          Paylaştığınız kişisel verilerinizin korunması, işlenmesi ve muhafaza edilmesi; başta özel hayatınızın gizliliği olmak üzere temel hak ve özgürlüklerinize ve kişisel verilerinizin güvenliğine gösterdiğimiz hassasiyetimiz gereğince 6698 sayılı Kişisel Verilerin Korunması Kanunu ve ilgili mevzuat kapsamında gerçekleşecektir. Taraflar arasındaki sözleşme kapsamında gerçekleştirilen faaliyetler çerçevesinde kişisel verileriniz işbu Kişisel Verilerin Korunmasına ve İşlenmesine Dair Onay Formu’nda belirtildiği şekilde korunacak ve işlenecektir.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">TANIMLAR</h2>
        <ul className="list-disc list-inside mb-4">
          <li><strong>Kullanıcı Verileri Koruma Kanunu:</strong> 6698 sayılı Kişisel Verilerin Korunması Kanunu’nu,</li>
          <li><strong>Açık rıza:</strong> Belirli bir konuya ilişkin, bilgilendirilmeye dayanan ve özgür iradeyle açıklanan rızayı,</li>
          <li><strong>Kişisel veri:</strong> Kimliği belirli veya belirlenebilir gerçek kişiye ilişkin her türlü bilgiyi (ad, soyad, adres, kimlik bilgileri, telefon vb.),</li>
          <li><strong>Özel nitelikli kişisel veri:</strong> Irk, etnik köken, siyasi düşünce, din, vakıf ya da sendika üyeliği, sağlığı, ceza mahkûmiyeti ve güvenlik tedbirleri gibi özel nitelikli verileri,</li>
          <li><strong>Kişisel verilerin işlenmesi:</strong> Kişisel verilerin tamamen veya kısmen otomatik olan ya da herhangi bir veri kayıt sisteminin parçası olmak kaydıyla otomatik olmayan yollarla elde edilmesi, kaydedilmesi, depolanması, muhafaza edilmesi, değiştirilmesi, yeniden düzenlenmesi, açıklanması, aktarılması, devralınması, elde edilebilir hâle getirilmesi, sınıflandırılması ya da kullanılmasının engellenmesi gibi veriler üzerinde gerçekleştirilen her türlü işlemi,</li>
          <li><strong>Veri sorumlusu:</strong> Kişisel verilerin işleme amaçlarını ve vasıtalarını belirleyen, veri kayıt sisteminin kurulmasından ve yönetilmesinden sorumlu olan gerçek veya tüzel kişiyi,</li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">VERİ SORUMLUSU</h2>
        <p className="mb-4">
          6698 sayılı Kullanıcı Verileri Koruma Kanunu uyarınca kişisel ve özel nitelikli kişisel verileriniz, Veri Sorumlusu tarafından yasal sınırlar çerçevesinde işlenecek, aktarılacak ve muhafaza edilebilecektir. Veri Sorumlusu’na dair bilgiler aşağıda mevcuttur:
        </p>
        <p className="mb-4"><strong>Veri Sorumlusu:</strong> Speeq</p>
        <p className="mb-4"><strong>Telefon:</strong> +90 (530) 819 05 40</p>
        <p className="mb-4"><strong>E-mail:</strong> hello@speeq.net</p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">KİŞİSEL VERİLERİNİZİN İŞLENME VE AKTARILMA AMACI</h2>
        <p className="mb-4">
          Veri Sorumlusu’nun vereceği hizmetlerden faydalanmanız; vatandaşlık işlemleri, başvuru işlemleri, evrak işlemleri gibi sözleşmede yer alan hukuki yükümlülüklerin yerine getirilebilmesi, ihtiyaç halinde sizinle irtibat kurulabilmesi, sözleşmesel gelişmelerin tarafınıza bildirilmesi, kişisel verileriniz doğrultusunda sözleşme kapsamında sizin için strateji belirlenmesi, öneri ve şikayetlerinizin kayıt altına alınması,  öneri ve şikayetlerinize göre memnuniyetin ve verimliliğin artırılması için ticari iş stratejilerinin belirlenmesi, ihtiyaçlarınız ve talepleriniz doğrultusunda daha iyi hizmet verilmesi, ödemeler hakkındaki bilgilerin ve gelişmelerin kayıt altına alınması ve takibinin yapılması, reklam, tanıtım ve kampanyalar hakkında sizi de bilgilendirmek, sözleşmesel hizmetler kapsamında analiz, listeleme, raporlama, profilleme ve istatistiki veriler oluşturabilmek, kanuni düzenlemelerin zorunlu kıldığı hallerde kamu kurum ve kuruluşlarının taleplerini yerine getirmek gibi amaçlarla kişisel verileriniz işlenecek ve aktarılacaktır.
        </p>
        <p className="mb-4">
          Kullanıcı Verileri Koruma Kanunu’nun 4. maddesi gereğince, kişisel verileriniz işlenirken hukuka ve dürüstlük kurallarına uygun, işlendikleri amaçla bağlantılı, sınırlı ve ölçülü olarak belirli, açık ve meşru amaçlar için işlenecektir ve ilgili mevzuatta öngörülen veya işlendikleri amaç için gerekli olan süre kadar muhafaza edilecektir.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">KİŞİSEL VERİLERİNİZİN TOPLANMA YÖNTEMLERİ</h2>
        <p className="mb-4">
          Kişisel verileriniz, Veri Sorumlusu tarafından otomatik ya da otomatik olmayan yöntemlerle, çağrı merkezi, internet sitesi, sosyal medya, iletişim formları, çerezler, imzaladığınız sözleşmeler, elektronik postalar, fakslar, mektuplar, mobil uygulamalar ve benzeri vasıtalarla sözlü, yazılı ya da elektronik olarak toplanabilecektir.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">KİŞİSEL VERİLERİNİZİN AKTARIMI</h2>
        <p className="mb-4">
          Kişisel verileriniz; Kullanıcı Verileri Koruma Kanunu’nun kişisel verilerin aktarılması ve yurtdışına aktarılmasına ilişkin hükümleri kapsamında işbu formda yer alan amaçlar ve hukuki sebeplerle; yurt içi ve/veya yurt dışındaki özel ve/veya kamu üniversiteleri, Milli Eğitim Bakanlığı (MEB), Öğrenci Seçme ve Yerleştirme Merkezi (ÖSYM) başta olmak üzere, ticari faaliyetlerimizin denetiminde çalıştığımız denetim ve muhasebe firmaları, hukuk büroları, yargı mercileri, iş ortaklarımız, çalışanlarımız gibi yurt içi ve/veya yurt dışı hizmet aldığımız üçüncü kişi, kurum ve kuruluşlara paylaşılıp aktarılabilmektedir.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">KİŞİSEL VERİLERİNİZİN SAKLANMASI</h2>
        <p className="mb-4">
          Kişisel verileriniz, işlenme amaçlarının gerektirdiği süreler boyunca saklanabilmektedir. Kullanıcı Verileri Koruma Kanunu ve ilgili diğer kanun hükümlerine uygun olarak işlenmiş olmasına rağmen, işlenmesini gerektiren sebeplerin ortadan kalkması hâlinde kişisel verileriniz resen veya talebiniz üzerine Veri Sorumlusu tarafından silinir, yok edilir veya anonim hâle getirilir.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">Kullanıcı Verileri Koruma KANUNU’NDAKİ HAKLARINIZ</h2>
        <ul className="list-decimal list-inside mb-4">
          <li>Kişisel verinizin işlenip işlenmediğini öğrenme,</li>
          <li>Kişisel verilerinizin işlenmişse buna ilişkin bilgi talep etme,</li>
          <li>Kişisel verilerinizin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme,</li>
          <li>Yurt içinde veya yurt dışında kişisel verilerinizin aktarıldığı üçüncü kişileri bilme,</li>
          <li>Kişisel verilerinizin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme,</li>
          <li>Kişisel verilerinizin işlenmesini gerektiren sebeplerin ortadan kalkması hâlinde kişisel verilerinizin silinmesini veya yok edilmesini isteme ve bu kapsamda yapılan işlemin kişisel verilerinizin aktarıldığı üçüncü kişilere bildirilmesini isteme,</li>
          <li>İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle aleyhinize bir sonucun ortaya çıkmasına itiraz etme,</li>
          <li>Kişisel verilerinizin kanuna aykırı olarak işlenmesi sebebiyle zarara uğranması hâlinde zararın giderilmesini talep etme,</li>
        </ul>
        <p className="mb-4">
          Haklarına sahip olup Veri Sorumlusu’ndan talep etme hakkınız mevcuttur. Kullanıcı Verileri Koruma Kanunu’nun 13. maddesi uyarınca haklarınıza dair taleplerinizi yazılı olarak veya Kişisel Verileri Koruma Kurulu’nun belirleyeceği diğer yöntemlerle Veri Sorumlusu’na iletmeniz gerekmektedir.
        </p>
        <p className="mb-4">
          Talepleriniz için kimliğinizi tespit eder gerekli belgeler ve talep ettiğiniz hakkınıza dair açıklamalarınızı içerir ıslak imzalı yazılı talebinizi [Adresiniz] adresimize bizzat olmak kaydıyla elden getirebilir, noter aracılığı ile gönderebilir, iadeli taahhütlü posta ile iletebilir veya Kişisel Verileri Koruma Kurulu’nca belirlenen diğer yöntemlerle tarafımıza iletebilirsiniz.
        </p>
        <p className="mb-4">
          Talepleriniz, talebinizin niteliğine göre en geç otuz gün içinde ücretsiz olarak sonuçlandırılır. Ancak, işlemin maliyet gerektirmesi durumunda Kişisel Verileri Koruma Kurulu’nca bir ücret öngörülmesi halinde Kurulca belirlenen tarifedeki ücret alınabilir.
        </p>
        <p className="mb-4">
          Ayrıca, kişisel verilerinizde değişiklik olması halinde yine aynı yöntemlerle tarafımıza mevcut güncel bilgilerinizi ve değişiklik talebinizi iletebilirsiniz.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">ONAY</h2>
        <p className="mb-4">
          Bu dokümanı onaylayarak 6698 Sayılı Kullanıcı Verileri Koruma Kanunu’nun 5. ve 6. maddeleri kapsamında şirketimize iletmiş olduğunuz kişisel verilerinizin ve özel nitelikli kişisel verilerinizin işbu formda açıkladığımız amaçlar ve hukuki sebepler gereğince Şirketimiz tarafından işlenmesine, muhafaza edilmesine ve aktarılmasına açıkça rıza göstermektesiniz. Kişisel verileriniz kanuni düzenlemelere uygun olarak ticari teamüllerde öngörülen sürelerde işlenecektir.
        </p>
        <p className="mb-4">
          Kanun kapsamında Veri Sorumlusu, Kullanıcı Verileri Koruma Kanunu’ndaki tüm sorumluluk ve yükümlülüklerine uymayı kabul etmektedir. Kişisel verilerinizin, Veri Sorumlusu’nun belirleyeceği yurtiçi veya yurtdışında bulunan 3. Kişilere, kurum ve kuruluşlara işbu verilerin saklanması, depolanması ve açık rıza gösterilen amaçlarla işlenmesi için aktarılmasına açıkça onay vermektesiniz.
        </p>
      </section>
    </div>
  );
};

export default Privacy
