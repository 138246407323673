import React from 'react';

const Terms = () => {
    return (
        <div className="container mx-auto p-4 md:p-8">
            <h1 className="text-3xl font-bold mb-6">Kullanım Şartları</h1>

            <section className="mb-8">
                <h2 className="text-2xl font-semibold mb-4">1. Giriş</h2>
                <p className="mb-4">Bu Kullanım Şartları, Speeq'in web sitesi ve hizmetlerinin kullanımını düzenler. Web sitemize ve hizmetlerimize erişerek bu şartları kabul etmiş olursunuz.</p>
            </section>

            <section className="mb-8">
                <h2 className="text-2xl font-semibold mb-4">2. Hizmetlerin Kapsamı</h2>
                <p className="mb-4">Speeq, kullanıcıların müşteri etkileşimlerini otomatikleştirmek için bir asistan bot sunar. Hizmetler, WhatsApp ve iFrame entegrasyonlarını içerir ve kullanıcıların müşteri hizmetleri, satış, randevu yönetimi ve sıkça sorulan sorular gibi işlevleri otomatikleştirmesine olanak tanır.</p>
            </section>

            <section className="mb-8">
                <h2 className="text-2xl font-semibold mb-4">3. Kayıt ve Hesap Yönetimi</h2>
                <p className="mb-4">Hizmetlerimize erişmek için bir hesap oluşturmanız gerekmektedir. Hesap bilgilerinizin doğru, güncel ve eksiksiz olmasını sağlamalısınız. Hesap güvenliğinizden siz sorumlusunuz.</p>
            </section>

            <section className="mb-8">
                <h2 className="text-2xl font-semibold mb-4">4. Kullanıcı Yükümlülükleri</h2>
                <ul className="list-disc ml-6 mb-4">
                    <li>Hizmetleri yalnızca yasal amaçlarla kullanmalısınız.</li>
                    <li>Speeq'in itibarını zedeleyecek, yasadışı veya etik olmayan faaliyetlerde bulunmamalısınız.</li>
                    <li>Hizmetleri kötüye kullanmamalı veya başkalarının kullanımını engellememelisiniz.</li>
                </ul>
            </section>

            <section className="mb-8">
                <h2 className="text-2xl font-semibold mb-4">5. Ücretlendirme ve Ödemeler</h2>
                <p className="mb-4">Hizmetler için belirlenen ücretler, ödeme koşulları ve yenileme şartları web sitemizde belirtilmiştir. Ödemelerinizi zamanında yapmanız gerekmektedir. Ödeme yapılmaması durumunda hizmetler askıya alınabilir.</p>
            </section>

            <section className="mb-8">
                <h2 className="text-2xl font-semibold mb-4">6. Hizmet Değişiklikleri ve Güncellemeler</h2>
                <p className="mb-4">Speeq, hizmetlerin işlevselliğini ve kullanım şartlarını değiştirme hakkını saklı tutar. Bu değişiklikler web sitemizde yayınlanacak ve kullanıcılar bilgilendirilecektir.</p>
            </section>

            <section className="mb-8">
                <h2 className="text-2xl font-semibold mb-4">7. Fikri Mülkiyet</h2>
                <p className="mb-4">Speeq ve hizmetleriyle ilgili tüm fikri mülkiyet hakları, Speeq'e aittir. Kullanıcılar, Speeq'in önceden yazılı izni olmaksızın bu hakları kullanamaz, çoğaltamaz veya dağıtamaz.</p>
            </section>

            <section className="mb-8">
                <h2 className="text-2xl font-semibold mb-4">8. Veri Koruma ve Gizlilik</h2>
                <p className="mb-4">
                    Speeq, kullanıcı verilerini Türkiye Cumhuriyeti yasalarına uygun olarak işler ve korur. Kullanıcılar, verilerinin işlenmesi ve saklanması konusunda bilgilendirilir ve onayları alınır. Daha fazla bilgi için <a href="/legal/privacy" className="text-blue-500">KVKK Aydınlatma Metni</a>'ni inceleyebilirsiniz.
                </p>
            </section>

            <section className="mb-8">
                <h2 className="text-2xl font-semibold mb-4">9. Sorumluluğun Sınırlandırılması</h2>
                <p className="mb-4">Speeq, hizmetlerin kesintisiz veya hatasız olacağını garanti etmez. Speeq, doğrudan veya dolaylı zararlardan sorumlu değildir.</p>
            </section>

            <section className="mb-8">
                <h2 className="text-2xl font-semibold mb-4">10. Anlaşmazlıkların Çözümü</h2>
                <p className="mb-4">Bu kullanım şartları, Türkiye Cumhuriyeti yasalarına tabidir. Herhangi bir anlaşmazlık durumunda İstanbul mahkemeleri yetkilidir.</p>
            </section>

            <section className="mb-8">
                <h2 className="text-2xl font-semibold mb-4">11. İletişim</h2>
                <p className="mb-4">Herhangi bir soru veya talebiniz için bizimle iletişime geçebilirsiniz:</p>
                <ul className="list-disc list-inside mb-4">
                    <li><strong>E-posta:</strong> <a href="mailto:hello@speeq.net" className="text-blue-500">hello@speeq.net</a></li>
                    <li><strong>Telefon:</strong> +90 (530) 819 05 40</li>
                    <li><strong>Adres:</strong> ATAKÖY 7-8-9-10. KISIM MAH. ÇOBANÇEŞME E-5 YAN YOL CAD. ATAKÖY TOWERS A BLOK NO: 20 /1 İÇ KAPI NO: 120 BAKIRKÖY/ İSTANBUL</li>
                </ul>
            </section>
        </div>
    );
};

export default Terms
