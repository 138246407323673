import { useState } from 'react';
import { Link } from 'react-router-dom'
import { MenuOutlined } from '@ant-design/icons';


const Header = () => {

  const [headerOpen, setHeaderOpen] = useState(false)

  const toggleHeader = () => {
    setHeaderOpen(!headerOpen)
  }

  const headerItems = [
    {
      route: '/',
      title: 'Home'
    },
    {
      route: '/products',
      title: 'Products'
    },
    {
      route: 'about-us',
      title: 'About Us'
    },
    {
      route: 'pricing',
      title: 'Pricing'
    },
    {
      route: 'contact',
      title: 'Contact'
    }
  ]
  return (
    <div className="fixed top-0 start-0 end-0 duration-500 items-center z-50">
      <div className="flex justify-between lg:flex-nowrap items-center bg-white shadow p-5">
        <div className="flex items-center">
          <Link to="/">
            <img
              src="/speeq.svg"
              alt="Vercel Logo"
              width={120}
            />
          </Link>
          <div className="lg:hidden flex items-center ml-auto px-2.5">
            <button
              className="hs-collapse-toggle"
              type="button"
              id="hs-unstyled-collapse"
              data-hs-collapse="#navbarCollapse"
            >
              <i data-lucide="menu" className="h-8 w-8 text-black"></i>
            </button>
          </div>
        </div>
        <div className="navigation hs-collapse transition-all duration-300 lg:basis-auto basis-full grow hidden items-center justify-center lg:flex mx-auto overflow-hidden mt-6 lg:mt-0 nav-light">
          <ul className="navbar-nav flex-col lg:flex-row gap-y-2 flex lg:items-center justify-center">
            {
              headerItems.map((header, index) => <li key={index} className="nav-item mx-1.5 transition-all text-dark lg:text-black group-[&.is-sticky]:text-dark duration-300 hover:text-primary [&.active]:!text-primary group-[&.is-sticky]:[&.active]:text-primary">
                <Link className="nav-link inline-flex items-center text-sm lg:text-base font-medium py-0.5 text-gray-500 px-2 capitalize" to={header.route}>{header.title}</Link>
              </li>)
            }

          </ul>
        </div>
        <MenuOutlined onClick={toggleHeader} className="md:hidden text-gray-500" />

        <div className="items-center lg:ml-auto md:flex hidden">
          <Link to="https://use.speeq.net" className="py-2 px-6 text-black">
            <span className="hidden sm:block">Login</span>
          </Link>
          <Link to="/trynow" className="py-2 px-6 inline-flex items-center gap-2 rounded-md text-base text-white bg-blue-600 hover:bg-primaryDark transition-all duration-500 font-medium">
            <span className="hidden sm:block">Try Now</span>
          </Link>
        </div>

      </div>
      {headerOpen && <div className="flex flex-col items-center m-2 shadow-md bg-white rounded-2xl border">
        {
          headerItems.map((header, index) => <div key={index} className="p-2 text-gray-500 font-medium text-[16px] block md:hidden">
            <Link to={header.route}>{header.title}</Link>
          </div>)
        }
      </div>}
    </div>

  );
};

export default Header;