import React, { useState, useEffect } from 'react';
import Loader from '../components/Loader';
import { useParams } from 'react-router-dom';

const Cases = () => {
    const [isLoading, setIsLoading] = useState(true);

    const { caseName } = useParams<{ caseName?: string }>(); // Make caseName optional

    const caseList: { [key: string]: { title: string; description: string; iframe: string } } = {
        support: {
            title: 'Support',
            description: "Provide your customers with 24/7 support in any language they need. Our service ensures that no matter where your customers are from or what language they speak, they can always get the help they need at any time of day or night. With Speeq, our advanced multilingual support system, we break down language barriers and offer seamless communication. Whether it's English, Spanish, Mandarin, or any of the other 100+ languages we support, Speeq guarantees accurate and efficient assistance. This means your customers can reach out with confidence, knowing they will be understood and their issues resolved quickly. Our commitment to round-the-clock service ensures that even in different time zones, your customers will always have access to the support they need. Enhance your customer service experience with Speeq, and show your customers that their satisfaction is your top priority, no matter where they are or what language they speak.",
            iframe: 'https://use.speeq.net/assist/6JYXDIL7RIH3948R4KZA28',
        },
        sales: {
            title: 'Sales',
            description: "You can make sales to your customers 24/7 in any language they prefer. With our service, you ensure that language barriers are never an obstacle, allowing your business to operate smoothly and efficiently around the clock. Speeq, our cutting-edge multilingual support system, empowers your business to communicate seamlessly with customers in over 100 languages. Whether it's a complex inquiry or a simple transaction, Speeq ensures clear and accurate communication, fostering trust and satisfaction. By integrating Speeq into your operations, you demonstrate a commitment to inclusivity and customer-centric service, enabling you to reach a global audience without the constraints of language differences. This round-the-clock, multilingual capability not only enhances your customer experience but also drives sales, as customers can confidently engage with your business anytime, anywhere. Embrace the power of Speeq and take your customer service and sales to the next level, ensuring that every customer interaction is smooth, efficient, and effective.",
            iframe: 'https://use.speeq.net/assist/6JYXDIL7RIH3948R4KZA2',
        },
        appointment: {
            title: 'Appointment',
            description: "Your customers can book appointments at times you set, making Speeq your closest assistant. This ensures seamless scheduling and efficient management, allowing you to provide personalized service with ease. With Speeq, you can offer a streamlined and user-friendly booking experience that caters to your customers' needs in over 100 languages. Whether your business operates locally or globally, Speeq handles all scheduling details, eliminating language barriers and ensuring accurate communication. Customers can effortlessly choose their preferred time slots, receive confirmations, and reschedule if necessary, all through a multilingual interface designed to enhance their experience. Speeq’s advanced features allow you to manage appointments efficiently, reduce no-shows, and maintain a well-organized calendar, so you can focus on delivering exceptional service. By integrating Speeq into your appointment scheduling process, you showcase your commitment to accessibility and customer satisfaction, making every interaction smooth and personalized. Let Speeq be your trusted assistant in managing appointments, ensuring that both you and your customers enjoy a hassle-free experience.",
            iframe: 'https://use.speeq.net/assist/6JYXDIL7RIH3948R4KZA',
        },
        faq: {
            title: 'FAQ',
            description: "Tired of answering the same questions over and over? Let Speeq handle it for you! Our service will provide consistent and accurate responses to frequently asked questions, saving you time and effort. With Speeq, you can automate responses in over 100 languages, ensuring that your customers receive timely and precise information no matter where they are or what language they speak. Speeq's intelligent system can handle a wide range of inquiries, from basic product details to complex support issues, reducing the workload on your team and enhancing overall efficiency. By leveraging advanced natural language processing, Speeq understands and responds to customer queries accurately, maintaining a high level of customer satisfaction. This automation allows your staff to focus on more critical tasks, improving productivity and service quality. Integrate Speeq into your customer service strategy and experience the benefits of a reliable, multilingual assistant that works tirelessly around the clock, ensuring that every customer interaction is smooth, efficient, and informative.",
            iframe: 'https://use.speeq.net/assist/6JYXDIL7RIH3948R4K',
        }
    }

    useEffect(() => {
        setIsLoading(true);
    }, [caseName]);

    const handleIframeLoad = () => {
        setIsLoading(false);
    };

    if (!caseName || !caseList.hasOwnProperty(caseName)) {
        return <></>
    }

    return (
        <div className="min-h-screen flex items-center justify-center flex-col px-4 md:px-36 my-24 md:my-10">
            <section className="grid grid-cols-1 lg:grid-cols-2 gap-10 items-center w-full">
                <div className="relative text-center lg:text-left">
                    <div className="font-medium text-3xl text-gray-600">{`Use Cases: ${caseList[caseName].title}`}</div>
                    <div className="text-gray-500 mt-5">{caseList[caseName].description}</div>
                </div>
                <div className="relative flex justify-center items-center w-full">
                    {isLoading && (
                        <div className="absolute inset-0 flex justify-center items-center bg-white bg-opacity-50">
                            <Loader size="10px" color="#356de7" />
                        </div>
                    )}
                    <iframe
                        className="w-full h-64 md:h-[50vh] rounded-xl shadow-md"
                        src={caseList[caseName].iframe}
                        onLoad={handleIframeLoad}
                        style={{ overflow: 'hidden' }}
                    />
                </div>
            </section>
        </div>
    );
};

export default Cases;