const cards = [
    {
        name: 'Basic',
        price: '$100',
        desc: 'Entry-level option for basic chat functionality, suitable for small businesses or startups.',
        features: ['Chat widget via iframe', '200 chats per day.'],
    },
    {
        name: 'Business',
        price: '$200',
        desc: 'Enhanced plan for businesses needing more advanced chat capabilities, including WhatsApp integration.',
        features: ['Chat widget via iframe', 'WhatsApp Integration', '200 iFrame chats per day.', '100 WhatsApp chats per day.'],
    },
    {
        name: 'Enterprise',
        price: '$500',
        desc: 'Customizable solution for large enterprises requiring tailored chat solutions and optional WhatsApp integration.',
        features: ['Chat widget via iframe', 'WhatsApp Integration', 'Pay as you iFrame chats per day.', '250 WhatsApp chats per day.'],
    }
]

const Pricing = () => {
    return (
        <div className="min-h-screen px-4 md:px-0 my-24 md:my-0">
            <div className="mt-16 md:mt-32 md:ml-32 mb-10 text-center md:text-left">
                <div className="text-4xl font-semibold text-gray-600">Pricing</div>
            </div>
            <div className="flex flex-wrap justify-center mx-auto w-full md:w-5/6">
                {
                    cards.map((card, index) => {
                        return <div key={index} className="w-full md:w-1/2 lg:w-1/3 px-4">
                            <div className="bg-white rounded-xl relative z-10 overflow-hidden border border-blue-700 border-opacity-20 shadow-pricing py-10 px-8 sm:p-12 lg:py-10 lg:px-6 xl:p-12 mb-10">
                                <span className="text-primary font-semibold text-lg block mb-4">
                                    {card.name}
                                </span>
                                <h2 className="font-bold text-dark mb-5 text-[42px]">
                                    {card.price}
                                    <span className="text-base text-body-color font-medium">
                                        / month
                                    </span>
                                </h2>
                                <p className="text-base text-body-color pb-8 mb-8 border-b border-[#F2F2F2]">
                                    {card.desc}
                                </p>
                                <div className="mb-7">
                                    {
                                        card.features.map((feature, index) => {
                                            return <p key={index} className="text-base text-body-color leading-loose mb-1">
                                                {feature}
                                            </p>
                                        })
                                    }

                                </div>
                                <a
                                    href="javascript:void(0)"
                                    className="w-full block text-base font-semibold text-primary bg-transparent border border-[#D4DEFF] rounded-md text-center p-4 hover:text-white hover:bg-primary hover:border-primary transition">
                                    Choose {card.name}
                                </a>
                                <div>
                                    <span className="absolute right-0 top-7 z-[-1]">
                                        <svg
                                            width="77"
                                            height="172"
                                            viewBox="0 0 77 172"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
                                            <defs>
                                                <linearGradient
                                                    id="paint0_linear"
                                                    x1="86"
                                                    y1="0"
                                                    x2="86"
                                                    y2="172"
                                                    gradientUnits="userSpaceOnUse"
                                                >
                                                    <stop stop-color="#3056D3" stop-opacity="0.09" />
                                                    <stop
                                                        offset="1"
                                                        stop-color="#C4C4C4"
                                                        stop-opacity="0"
                                                    />
                                                </linearGradient>
                                            </defs>
                                        </svg>
                                    </span>
                                    <span className="absolute right-4 top-4 z-[-1]">
                                        <svg
                                            width="41"
                                            height="89"
                                            viewBox="0 0 41 89"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <circle
                                                cx="38.9138"
                                                cy="87.4849"
                                                r="1.42021"
                                                transform="rotate(180 38.9138 87.4849)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="38.9138"
                                                cy="74.9871"
                                                r="1.42021"
                                                transform="rotate(180 38.9138 74.9871)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="38.9138"
                                                cy="62.4892"
                                                r="1.42021"
                                                transform="rotate(180 38.9138 62.4892)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="38.9138"
                                                cy="38.3457"
                                                r="1.42021"
                                                transform="rotate(180 38.9138 38.3457)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="38.9138"
                                                cy="13.634"
                                                r="1.42021"
                                                transform="rotate(180 38.9138 13.634)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="38.9138"
                                                cy="50.2754"
                                                r="1.42021"
                                                transform="rotate(180 38.9138 50.2754)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="38.9138"
                                                cy="26.1319"
                                                r="1.42021"
                                                transform="rotate(180 38.9138 26.1319)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="38.9138"
                                                cy="1.42021"
                                                r="1.42021"
                                                transform="rotate(180 38.9138 1.42021)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="26.4157"
                                                cy="87.4849"
                                                r="1.42021"
                                                transform="rotate(180 26.4157 87.4849)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="26.4157"
                                                cy="74.9871"
                                                r="1.42021"
                                                transform="rotate(180 26.4157 74.9871)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="26.4157"
                                                cy="62.4892"
                                                r="1.42021"
                                                transform="rotate(180 26.4157 62.4892)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="26.4157"
                                                cy="38.3457"
                                                r="1.42021"
                                                transform="rotate(180 26.4157 38.3457)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="26.4157"
                                                cy="13.634"
                                                r="1.42021"
                                                transform="rotate(180 26.4157 13.634)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="26.4157"
                                                cy="50.2754"
                                                r="1.42021"
                                                transform="rotate(180 26.4157 50.2754)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="26.4157"
                                                cy="26.1319"
                                                r="1.42021"
                                                transform="rotate(180 26.4157 26.1319)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="26.4157"
                                                cy="1.4202"
                                                r="1.42021"
                                                transform="rotate(180 26.4157 1.4202)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="13.9177"
                                                cy="87.4849"
                                                r="1.42021"
                                                transform="rotate(180 13.9177 87.4849)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="13.9177"
                                                cy="74.9871"
                                                r="1.42021"
                                                transform="rotate(180 13.9177 74.9871)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="13.9177"
                                                cy="62.4892"
                                                r="1.42021"
                                                transform="rotate(180 13.9177 62.4892)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="13.9177"
                                                cy="38.3457"
                                                r="1.42021"
                                                transform="rotate(180 13.9177 38.3457)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="13.9177"
                                                cy="13.634"
                                                r="1.42021"
                                                transform="rotate(180 13.9177 13.634)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="13.9177"
                                                cy="50.2754"
                                                r="1.42021"
                                                transform="rotate(180 13.9177 50.2754)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="13.9177"
                                                cy="26.1319"
                                                r="1.42021"
                                                transform="rotate(180 13.9177 26.1319)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="13.9177"
                                                cy="1.42019"
                                                r="1.42021"
                                                transform="rotate(180 13.9177 1.42019)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="1.41963"
                                                cy="87.4849"
                                                r="1.42021"
                                                transform="rotate(180 1.41963 87.4849)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="1.41963"
                                                cy="74.9871"
                                                r="1.42021"
                                                transform="rotate(180 1.41963 74.9871)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="1.41963"
                                                cy="62.4892"
                                                r="1.42021"
                                                transform="rotate(180 1.41963 62.4892)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="1.41963"
                                                cy="38.3457"
                                                r="1.42021"
                                                transform="rotate(180 1.41963 38.3457)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="1.41963"
                                                cy="13.634"
                                                r="1.42021"
                                                transform="rotate(180 1.41963 13.634)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="1.41963"
                                                cy="50.2754"
                                                r="1.42021"
                                                transform="rotate(180 1.41963 50.2754)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="1.41963"
                                                cy="26.1319"
                                                r="1.42021"
                                                transform="rotate(180 1.41963 26.1319)"
                                                fill="#3056D3"
                                            />
                                            <circle
                                                cx="1.41963"
                                                cy="1.4202"
                                                r="1.42021"
                                                transform="rotate(180 1.41963 1.4202)"
                                                fill="#3056D3"
                                            />
                                        </svg>
                                    </span>
                                </div>
                            </div>
                        </div>
                    })
                }
            </div>
        </div>




    );
}

export default Pricing