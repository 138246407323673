const TryNow = () => {
    return (
        <div className="min-h-screen">
            <div className="m-32">
                <div className="text-2xl font-semibold text-gray-600 mb-5">Try Now</div>
                <iframe 
                    className="w-full h-screen md:h-[70vh] rounded-xl shadow-md"
                    src="https://use.speeq.net/assist/6JYXDIL7RIH3948R4KZA28">

                </iframe>
            </div>
        </div>


    );
}

export default TryNow