const ContactUs = () => {
  return (
    <div className="min-h-screen flex flex-col px-4 md:px-36 py-8 md:py-16 my-24 md:my-10">
      <div className="text-4xl font-semibold text-gray-600 mb-4 md:mb-2 text-center md:text-left">Contact Us</div>
      <p className="text-gray-600 mb-8 text-center md:text-left">We’re here to help! Whether you have questions, feedback, or need support, our team at Speeq is ready to assist you.</p>

      <div className="mb-12">
        <h2 className="text-2xl text-gray-800 mb-4 font-medium text-center md:text-left">Get in Touch</h2>
        <p className="text-gray-600 mb-4 text-center md:text-left">
          For any questions, support, or sales inquiries, please feel free to reach out to us at:
          <a href="mailto:hello@speeq.net" className="text-blue-500 underline ml-1">hello@speeq.net</a>
        </p>
      </div>

      <div className="mb-12">
        <h2 className="text-2xl text-gray-800 mb-4 font-medium text-center md:text-left">Follow Us</h2>
        <p className="text-gray-600 mb-4 text-center md:text-left">
          Stay connected with us on social media for the latest updates, news, and special offers:
        </p>
        <ul className="list-none text-center md:text-left">
          <li className="mb-2 font-semibold">Twitter: <a href="https://twitter.com/speeq" className="text-blue-500">@speeqtr</a></li>
          <li className="mb-2 font-semibold">YouTube: <a href="https://www.youtube.com/@speeqtr" className="text-blue-500">@speeqtr</a></li>
          <li className="mb-2 font-semibold">Instagram: <a href="https://instagram.com/speeq.tr" className="text-blue-500">@speeq.tr</a></li>
        </ul>
      </div>

      <div>
        <h2 className="text-2xl text-gray-800 mb-4 font-medium text-center md:text-left">Our Office</h2>
        <div className="text-gray-600 text-center md:text-left">
          <div><span className="text-black font-semibold">Address:</span> Ataköy 7-8-9-10th Section Neighborhood, Çobançeşme E-5 Side Road Street, Ataköy Towers A Block, No: 20/1, Internal Door No: 120, Bakırköy, Istanbul, Turkey</div>
          <div><span className="text-black font-semibold">Phone:</span> +90 (530) 819 05 40</div>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
