const Cookie = () => {
  return (
    <div className="container mx-auto p-4 md:p-8">
      <h1 className="text-3xl font-bold mb-4">ÇEREZ POLİTİKASI</h1>

      <section>
        <h2 className="text-2xl font-semibold mb-2">GİRİŞ</h2>
        <p className="mb-4">
          Bu Çerez Politikası, Speeq'in web sitemizde çerezleri ve benzer teknolojileri nasıl kullandığını açıklar. Web sitemizi kullanarak, bu politikada açıklandığı şekilde çerezlerin kullanılmasını kabul etmiş olursunuz.
        </p>
      </section>

      <section>
        <h2 className="text-2xl font-semibold mb-2">ÇEREZLER NEDİR?</h2>
        <p className="mb-4">
          Çerezler, tarayıcınız tarafından bilgisayarınıza veya cihazınıza yerleştirilen ve bize daha iyi bir kullanıcı deneyimi sunmamıza yardımcı olan küçük metin dosyalarıdır. Çerezler, cihazınızı tanımamıza ve tercihlerinizi veya geçmişteki işlemlerinizi hatırlamamıza olanak tanır.
        </p>
      </section>

      <section>
        <h2 className="text-2xl font-semibold mb-2">ÇEREZ TÜRLERİ VE NASIL KULLANILDIKLARI</h2>

        <h3 className="text-xl font-semibold mb-2">GEREKLİ ÇEREZLER</h3>
        <p className="mb-4">
          Bu çerezler, web sitemizin çalışması için gereklidir ve sistemlerimizde devre dışı bırakılamaz. Genellikle, yalnızca gizlilik tercihlerinizi ayarlamak, oturum açmak veya formları doldurmak gibi hizmet taleplerinize yanıt olarak ayarlanırlar.
        </p>
        <p className="mb-4"><strong>Örnek:</strong> Kullanıcı oturum çerezleri.</p>

        <h3 className="text-xl font-semibold mb-2">PERFORMANS ÇEREZLERİ</h3>
        <p className="mb-4">
          Bu çerezler, ziyaretçilerin web sitemizi nasıl kullandıkları hakkında bilgi toplar, örneğin en çok hangi sayfaların ziyaret edildiği veya hata mesajlarının alınıp alınmadığı. Bu çerezler, web sitemizin performansını ve işlevselliğini iyileştirmemize yardımcı olur.
        </p>
        <p className="mb-4"><strong>Örnek:</strong> Google Analytics çerezleri.</p>

        <h3 className="text-xl font-semibold mb-2">FONKSİYONEL ÇEREZLER</h3>
        <p className="mb-4">
          Bu çerezler, web sitemizi geliştirmek için kullanılır ve sitede yaptığınız seçimleri (örneğin, kullanıcı adınızı, dilinizi veya bulunduğunuz bölgeyi hatırlamak gibi) sağlar. Bu çerezler, daha kişiselleştirilmiş ve gelişmiş bir deneyim sunar.
        </p>
        <p className="mb-4"><strong>Örnek:</strong> Dil tercih çerezleri.</p>

        <h3 className="text-xl font-semibold mb-2">HEDEFLEME ÇEREZLERİ</h3>
        <p className="mb-4">
          Bu çerezler, ilgi alanlarınıza daha uygun içerik ve reklamlar sunmak için kullanılır. Ayrıca, bir reklam kampanyasının etkinliğini ölçmek için de kullanılabilirler.
        </p>
        <p className="mb-4"><strong>Örnek:</strong> Reklam çerezleri.</p>
      </section>

      <section>
        <h2 className="text-2xl font-semibold mb-2">ÜÇÜNCÜ TARAF ÇEREZLER</h2>
        <p className="mb-4">
          Web sitemiz, üçüncü taraf hizmet sağlayıcılarından çerezler içerebilir. Bu çerezler, üçüncü taraflar tarafından cihazınıza yerleştirilen ve yönetilen çerezlerdir. Bu çerezler hakkında daha fazla bilgi için ilgili üçüncü tarafların çerez politikalarını incelemenizi öneririz.
        </p>
      </section>

      <section>
        <h2 className="text-2xl font-semibold mb-2">ÇEREZ YÖNETİMİ</h2>
        <p className="mb-4">
          Çerezleri tarayıcı ayarlarınız aracılığıyla yönetebilir veya silebilirsiniz. Çoğu web tarayıcısı, çerezleri otomatik olarak kabul eder, ancak tarayıcı ayarlarınızı değiştirerek çerezlerin kabul edilmesini reddedebilirsiniz. Çerezleri devre dışı bırakmanın web sitemizin belirli özelliklerini kullanma yeteneğinizi etkileyebileceğini unutmayın.
        </p>
        <ul className="list-disc list-inside mb-4">
          <li><strong>Tarayıcı Ayarları:</strong> Çerez ayarlarını tarayıcınızdan yönetebilirsiniz. Tarayıcınızdaki yardım menüsünden veya tarayıcı üreticisinin web sitesinden çerez yönetimi hakkında bilgi alabilirsiniz.</li>
          <li><strong>Üçüncü Taraf Araçlar:</strong> Çevrimiçi olarak çerez yönetimi ve reklam takibini engelleme araçları bulabilirsiniz.</li>
        </ul>
      </section>

      <section>
        <h2 className="text-2xl font-semibold mb-2">ÇEREZLERİN SAKLANMA SÜRESİ</h2>
        <p className="mb-4">
          Kullanılan çerezlerin saklanma süresi, çerez türüne ve işlevine bağlı olarak değişiklik gösterebilir. Geçici çerezler, tarayıcı kapatıldığında otomatik olarak silinirken, kalıcı çerezler belirli bir süre boyunca cihazınızda saklanabilir.
        </p>
      </section>

      <section>
        <h2 className="text-2xl font-semibold mb-2">BU POLİTİKADAKİ DEĞİŞİKLİKLER</h2>
        <p className="mb-4">
          Bu Çerez Politikasını zaman zaman güncelleyebiliriz. Herhangi bir değişiklik bu sayfada yayınlanacak ve bu politikayı düzenli olarak gözden geçirmenizi öneririz. Bu Çerez Politikası en son 17 Temmuz 2024 tarihinde güncellenmiştir.
        </p>
      </section>

      <section>
        <h2 className="text-2xl font-semibold mb-2">İLETİŞİM</h2>
        <p className="mb-4">
          Bu Çerez Politikası ile ilgili herhangi bir sorunuz veya endişeniz varsa, lütfen bizimle iletişime geçin:
        </p>
        <ul className="list-disc list-inside mb-4">
          <li><strong>E-posta:</strong> <a href="mailto:hello@speeq.net" className="text-blue-500">hello@speeq.net</a></li>
          <li><strong>Telefon:</strong> +90 (530) 819 05 40</li>
          <li><strong>Adres:</strong> ATAKÖY 7-8-9-10. KISIM MAH. ÇOBANÇEŞME E-5 YAN YOL CAD. ATAKÖY TOWERS A BLOK NO: 20 /1 İÇ KAPI NO: 120 BAKIRKÖY/ İSTANBUL</li>
        </ul>
      </section>
    </div>
  );
};

export default Cookie;
