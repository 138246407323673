import React, { useState, useEffect } from 'react';
import Loader from '../components/Loader';
import { Link } from 'react-router-dom';

const Home = () => {
  const [isLoading, setIsLoading] = useState(true);

  const handleIframeLoad = () => {
    setIsLoading(false);
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center py-20 md:py-40 gap-y-16 md:gap-y-32">
      <section className="grid lg:grid-cols-2 grid-cols-1 gap-x-6 gap-y-10 items-center px-4 md:px-8 lg:px-16">
        <div className="relative">
          <h1 className="text-3xl md:text-4xl lg:text-5xl text-black tracking-normal leading-normal font-bold max-w-2xl mt-4">
            Enable continuous progress with <span className="text-blue-500">Speeq</span> in every conversation.
          </h1>
          <p className="text-base text-gray-500 max-w-lg font-light my-6">
            With Speeq, you can answer your clients quickly and efficiently in every language, ensuring that no matter where your customers are from or what language they speak, they always receive answers by your pre-defined scenarios.
          </p>
          <Link className="py-2 px-6 rounded-md text-base text-white bg-blue-600" to="/trynow">Try Now</Link>
        </div>

        <div className="relative flex justify-center items-center w-full">
          {isLoading && (
            <div className="absolute inset-0 flex justify-center items-center bg-white bg-opacity-50">
              <Loader size="10px" color="#356de7" />
            </div>
          )}
          <iframe
            className="w-full h-64 md:h-[50vh] rounded-xl shadow-md"
            src="https://use.speeq.net/assist/6JYXDIL7RIH3948R4KZA28"
            onLoad={handleIframeLoad}
            style={{ overflow: 'hidden' }}
          />
        </div>
      </section>

      <section className="flex flex-col md:flex-row bg-blue-50 px-4 md:px-8 lg:px-32 w-full items-center rounded-3xl py-8 gap-y-8 md:gap-y-0">
        <div className="md:w-2/5 text-center md:text-left">
          <div className="text-3xl font-medium">Integrate <span className="text-blue-600">Speeq</span> into your business</div>
          <div className="text-gray-500 mt-3">
            Speeq seamlessly integrates with WhatsApp, Instagram and supports iFrame integration to your website, empowering businesses to automate customer interactions and enhance user engagement. Easily embed Speeq to streamline communication channels and improve customer experience.
          </div>
        </div>
        <div className="md:w-3/5 flex justify-center items-center">
          <div className="flex justify-center align-center p-10 md:p-20 gap-10">
            <div className="bg-green-500 h-24 w-24 md:h-36 md:w-36 rounded-[2rem] text-white flex justify-center items-center">
              <img src="/whatsapp.svg" className="w-14 md:w-24" alt="WhatsApp" />
            </div>
            <div className="h-24 w-24 md:h-36 md:w-36">
              <img src="/instagram.svg" alt="Instagram" />
            </div>
            <div className="bg-purple-600 h-24 w-24 md:h-36 md:w-36 rounded-[2rem] text-white text-4xl md:text-6xl font-medium flex justify-center items-center">{`</>`}</div>
          </div>
        </div>
      </section>

      <section className="px-4 md:px-8 lg:px-32 w-full">
        <div className="font-bold text-3xl text-center md:text-left">How <span className="text-blue-600">Speeq</span> Can Help Your Business</div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
          {[
            {
              title: 'Support',
              description: 'Provide your customers with 24/7 support in any language they need. Our service ensures that no matter where your customers are from or what language they speak, they can always get the help they need at any time of day or night.',
              link: '/cases/support',
            },
            {
              title: 'Sales',
              description: 'You can make sales to your customers 24/7 in any language they prefer. With our service, you ensure that language barriers are never an obstacle, allowing your business to operate smoothly and efficiently around the clock.',
              link: '/cases/sales',
            },
            {
              title: 'Appointment',
              description: 'Your customers can book appointments at times you set, making Speeq your closest assistant. This ensures seamless scheduling and efficient management, allowing you to provide personalized service with ease.',
              link: '/cases/appointment',
            },
            {
              title: 'FAQ',
              description: 'Tired of answering the same questions over and over? Let Speeq handle it for you! Our service will provide consistent and accurate responses to frequently asked questions, saving you time and effort.',
              link: '/cases/faq',
            },
          ].map((caseItem, index) => (
            <div key={index} className="w-full bg-blue-50 p-6 rounded-3xl flex flex-col">
              <div className="font-bold text-2xl">{caseItem.title}</div>
              <div className="mt-2 text-gray-600 flex-grow">
                {caseItem.description}
              </div>
              <div className="mt-4 self-end bg-blue-600 px-3 py-2 text-white rounded-xl text-sm">
                <Link to={caseItem.link}>Try This Case</Link>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default Home;
