const AboutUs = () => {
    return (
        <div className="min-h-screen px-4 md:px-32 my-24 md:my-10">
            <div className="my-16 md:my-32">
                <div className="text-2xl font-semibold text-gray-600 text-center md:text-left">About Us</div>
                <div className="text-gray-500 mt-5 text-center md:text-left">Speeq is a leading provider of AI-driven chatbot solutions, dedicated to transforming how businesses interact with their customers. Our mission is to streamline customer communication through innovative technology, empowering businesses to deliver personalized and efficient customer experiences. With customizable chatbot integrations for websites and seamless connectivity with platforms like WhatsApp, Speeq enables businesses to engage customers across multiple channels effectively. Trusted by businesses worldwide, Speeq combines advanced AI capabilities with a customer-centric approach to enhance satisfaction, optimize operations, and drive growth. Join us in revolutionizing customer engagement with Speeq's intelligent chatbot solutions.</div>
            </div>
            <div className="my-16 md:my-32">
                <div className="text-2xl font-semibold text-gray-600 text-center md:text-left">What Sets Us Apart</div>
                <div className="text-gray-500 mt-5 text-center md:text-left">Speeq stands out for its innovative approach and robust features designed to meet the dynamic needs of today’s businesses.
                    <ul className="list-disc pl-5 space-y-2 text-left md:text-left">
                        <li><span className="font-semibold">Customizable Solutions:</span> Tailor Speeq’s chatbot functionalities to fit your unique business requirements, from personalized responses to seamless integration with existing platforms.</li>

                        <li><span className="font-semibold">Multi-Channel Capabilities:</span> Whether through our flexible iFrame integration for web platforms or our integration with popular messaging apps like WhatsApp, Speeq ensures consistent and effective customer engagement across multiple channels.</li>

                        <li><span className="font-semibold">AI-Powered Intelligence:</span> Leveraging advanced AI technology, Speeq not only understands and responds to customer queries in real-time but also learns from interactions to continuously improve performance and accuracy.</li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default AboutUs;
