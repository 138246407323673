const Products = () => {
    return (
        <div className="min-h-screen flex items-center justify-center flex-col mx-4 md:mx-36 my-24 md:my-0 pt-16 md:pt-32 md:pb-32">
            <div className="grid grid-cols-1 md:grid-cols-4 gap-4 w-full">
                <div className="col-span-1 flex justify-center md:justify-start">
                    <div className="bg-green-500 h-52 w-52 rounded-[2rem] text-white flex justify-center items-center">
                        <img src="/whatsapp.svg" className="w-32" alt="WhatsApp" />
                    </div>
                </div>
                <div className="col-span-1 md:col-span-3 mt-5 md:mt-0">
                    <div className="font-bold text-2xl text-center md:text-left">WhatsApp</div>
                    <div className="text-gray-500 mt-2 text-center md:text-left">
                        Speeq supports integration with WhatsApp, enabling businesses to deploy chatbots across the WhatsApp platform. This integration extends the reach of customer engagement beyond websites, tapping into WhatsApp's extensive user base. By using Speeq on WhatsApp, businesses can offer personalized customer interactions, handle inquiries, and provide support via a familiar and widely used messaging platform. This approach not only improves accessibility for customers but also streamlines communication processes for businesses, ensuring consistent service delivery across different channels.
                    </div>
                </div>
                <div className="col-span-1 md:col-span-3 mt-5 md:mt-0 order-last md:order-none">
                    <div className="font-bold text-2xl text-center md:text-left">Instagram</div>
                    <div className="text-gray-500 mt-2 text-center md:text-left">
                        Speeq supports integration with Instagram, allowing businesses to engage with customers directly on one of the most popular social media platforms. This integration enables businesses to deploy chatbots on Instagram, providing personalized interactions, handling inquiries, and offering support through Instagram Direct Messages. By leveraging Speeq on Instagram, businesses can tap into a vast user base, enhance their social media presence, and streamline customer communication. This approach ensures consistent and efficient service delivery, improving customer satisfaction and engagement across multiple channels.
                    </div>
                </div>
                <div className="col-span-1 flex justify-center md:justify-end">
                    <div className="h-52 w-52">
                        <img src="/instagram.svg" alt="Instagram" />
                    </div>
                </div>
                <div className="col-span-1 flex justify-center md:justify-start">
                    <div className="bg-purple-600 h-52 w-52 rounded-[2rem] text-white text-8xl font-medium flex justify-center items-center">
                        {`</>`}
                    </div>
                </div>
                <div className="col-span-1 md:col-span-3 mt-5 md:mt-0">
                    <div className="font-bold text-2xl text-center md:text-left">iFrame</div>
                    <div className="text-gray-500 mt-2 text-center md:text-left">
                        Speeq provides businesses with an iFrame integration option, allowing them to embed a pre-defined chatbot directly onto any website. This integration is seamless and flexible, enabling businesses to maintain their branding and user experience while using Speeq's conversational capabilities. By embedding the chatbot via iFrame, businesses can enhance user engagement and provide immediate support to website visitors, automating responses to common queries and guiding users through interactive experiences right within their web platform.
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Products;
