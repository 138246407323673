import React from 'react';

interface LoaderProps {
    size?: string;
    color?: string;
}

const Loader: React.FC<LoaderProps> = ({ size = '8px', color = '#000' }) => {
    return (
        <div className='flex space-x-2'>
            <div className="rounded-full animate-bounce" style={{ width: size, height: size, animationDelay: '-1.2s', background: color }}></div>
            <div className="rounded-full animate-bounce" style={{ width: size, height: size, animationDelay: '-0.6s', background: color}}></div>
            <div className="rounded-full animate-bounce" style={{ width: size, height: size, background: color }}></div>
        </div>
    );
};

export default Loader;
