import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-black px-4 md:px-8 lg:px-16 rounded-tl-3xl rounded-tr-3xl text-gray-300 flex flex-col items-center py-8">
      <div className="flex flex-wrap w-full md:flex-nowrap p-10 gap-y-8 md:gap-y-0">
        <div className="w-full md:w-[35%] mb-6 md:mb-0">
          <div className="flex items-center gap-2">
            <img src="/speeq-icon-1000.png" width={60} alt="Speeq Icon" />
            <div className="font-medium text-xl">Speeq</div>
          </div>
          <div className="ml-1 mt-2 pr-10 text-gray-500">
            Speeq is a multi-lingual chatbot assistant that elevates your customer relations to the next level.
          </div>
          <div className="ml-1 mt-10 flex gap-4">
            <div className="border border-gray-300 rounded-full w-10 h-10 flex justify-center items-center">
              <Link target="_blank" to="https://x.com/speeqtr">X</Link>
            </div>
            <div className="border border-gray-300 rounded-full w-10 h-10 flex justify-center items-center">
              <Link target="_blank" to="https://instagram.com/speeq.tr">IG</Link>
            </div>
            <div className="border border-gray-300 rounded-full w-10 h-10 flex justify-center items-center">
              <Link target="_blank" to="https://www.youtube.com/@speeqtr">YT</Link>
            </div>
          </div>
        </div>
        <div className="w-full md:w-[15%] mb-6 md:mb-0">
          <div className="font-medium">Pages</div>
          <div className="mt-3 text-gray-500 flex flex-col space-y-2">
            <Link to="/">Home</Link>
            <Link to="/about-us">About Us</Link>
            <Link to="/products">Products</Link>
            <Link to="/pricing">Pricing</Link>
            <Link to="/contact">Contact</Link>
          </div>
        </div>
        <div className="w-full md:w-[15%] mb-6 md:mb-0">
          <div className="font-medium">Legal</div>
          <div className="mt-3 text-gray-500 flex flex-col space-y-2">
            <Link to="/legal/privacy">Privacy</Link>
            <Link to="/legal/cookie">Cookie</Link>
            <Link to="/legal/tos">Terms</Link>
          </div>
        </div>
        <div className="w-full md:w-[15%] mb-6 md:mb-0">
          <div className="font-medium">Use Cases</div>
          <div className="mt-3 text-gray-500 flex flex-col space-y-2">
            <Link to="/cases/support">Support</Link>
            <Link to="/cases/sales">Sales</Link>
            <Link to="/cases/appointment">Appointment</Link>
            <Link to="/cases/faq">FAQ</Link>
          </div>
        </div>
        <div className="w-full md:w-[20%]">
          <div className="font-medium">Address</div>
          <div className="mt-3 text-gray-500">
            ATAKÖY 7-8-9-10. KISIM MAH. ÇOBANÇEŞME E-5 YAN YOL CAD. ATAKÖY TOWERS A BLOK NO: 20 /1 İÇ KAPI NO: 120 BAKIRKÖY/ İSTANBUL
          </div>
          <div className="font-medium mt-5">Phone</div>
          <div className="mt-1 text-gray-500">+90 (530) 819 05 40</div>
          <div className="font-medium mt-5">Mail</div>
          <div className="mt-1 text-gray-500">hello@speeq.net</div>
        </div>
      </div>
      <div className="m-5 text-gray-500">&copy; {new Date().getFullYear()} Speeq. All rights reserved.</div>
    </footer>
  );
};

export default Footer;
