import React from 'react';
import { useParams } from 'react-router-dom';
import Privacy from '../components/Privacy';
import Cookie from '../components/Cookie';
import Terms from '../components/Terms';

const Cases: React.FC = () => {
    const { agreement } = useParams<{ agreement?: string }>();

    const agreementList: { [key: string]: { description: React.ReactNode } } = {
        privacy: {
            description: <Privacy />
        },
        cookie: {
            description: <Cookie />
        },
        tos: {
            description: <Terms />
        },
    };

    if (!agreement || !agreementList.hasOwnProperty(agreement)) {
        return <></>;
    }

    return (
        <div className="min-h-screen flex items-center justify-center p-4 md:p-8 my-24 md:my-10">
            <div className="bg-white rounded-lg p-6 max-w-4xl w-full">
                <div className="text-gray-500">{agreementList[agreement].description}</div>
            </div>
        </div>
    );
};

export default Cases;
