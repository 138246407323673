
import { Routes, Route, Outlet, Link } from 'react-router-dom'
import Header from './components/Header'
import Footer from './components/Footer'



import Home from './pages/Home'
import Products from './pages/Products'
import AboutUs from './pages/AboutUs'
import Contact from './pages/Contact'
import Cases from './pages/Cases'
import Legal from './pages/Legal'
import Pricing from './pages/Pricing'
import TryNow from './pages/TryNow'

const App = () => {
  return (
    <Routes>
      <Route path='/' element={<Layout />}>
        <Route index element={<Home />} />
        <Route path='products' element={<Products />} />
        <Route path='about-us' element={<AboutUs />} />
        <Route path='contact' element={<Contact />} />
        <Route path='cases/:caseName' element={<Cases />} />
        <Route path='legal/:agreement' element={<Legal />} />
        <Route path='pricing' element={<Pricing />} />
        <Route path='trynow' element={<TryNow />} />
        <Route path='*' element={<NoMatch />} />
      </Route>
    </Routes>
  )
}



function Layout() {
  return (
    <div>
      <Header />
      <Outlet />
      <Footer />
    </div>
  );
}



function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to='/'>Go to the home page</Link>
      </p>
    </div>
  );
}
export default App